#Feature1_0 .ant-row > .ant-col > .kf6rjvpynhr-editor_css {
  font-family: Tahoma;
}
#Feature1_0 .ant-row > .ant-col > .kf6rk0jgcmo-editor_css {
  font-family: Tahoma;
}
#Feature1_0 .ant-row > .kfcnmww694-editor_css {
  height: 75%;
}
#Feature1_0 .ant-row > .kfcl2hi25zs-editor_css {
  height: 75%;
}
