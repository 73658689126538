.opendxp-contact-us-form {
  .ant-form-item {
    padding-bottom: 20px;

    input,
    textarea {
      webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0,0,0,.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &:focus {
        border-color: #40a9ff;
        border-right-width: 1px!important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(24,144,255,.2);
        box-shadow: 0 0 0 2px rgba(24,144,255,.2);
      }
    }
  }

  .opendxp-contact-us-form-reqd {
    position: relative;

    &:before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun,sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .ant-form-item-label {
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
  }

  .opendxp-contact-us-submit {
    text-align: center;

    button {
      margin: auto;
      padding: 0 75px;
    }
  }
}

.opendxp-contact-spiner {
  text-align: center;

  .ant-spin-dot {
    font-size: 40px;
  }

  h3 {
    padding-top: 25px;
  }
}

@primary-color: #1DA57A;