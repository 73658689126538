.opendxp-layout-wrapper {
  .banner3 .banner3-button {
    line-height: 33px;
  }

  .pricing0-wrapper {
    padding-bottom: 50px;

    .ant-btn.ant-btn-primary {
      display: block;
      background: #034365;
      background: linear-gradient(to right, #034365 0%, #001b33 100%);
      box-shadow: 0 8px 16px #0a52ab;
      border: none;
      transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 132px;
      line-height: 40px;
      height: 42px;
      border-radius: 42px;
    }

    .pricing0-text-wrapper .pricing0-title {
      margin: 20px auto 16px;
    }
  }

  .content6-text {
    padding-right: 15px;
  }

  .pricing-image-wrapper {
    img {
      width: 80%;
    }
  }

  .teams0-image {
    border-radius: 0;
    width: auto;
    height: auto;
  }

  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .kfclp9fcd8i-editor_css {
    margin: 30px auto;
  }
}

@primary-color: #1DA57A;